import React from 'react'

function Energylandia() {
  return (
    <div>Energylandia
      <p>Trip to Energylandia soon......</p>
      <p>Contact Us on +48 508-392-683</p>
    </div>
  )
}

export default Energylandia